(function () {
	const ticker = function () {
		if (typeof Splide === 'undefined') {
			return;
		}

		const targets = document.querySelectorAll('[data-js-ticker]');

		if (!targets || !targets.length) {
			return;
		}

		for (let i = 0; i < targets.length; i++) {
			const target = targets[i];
			const speed = !isNaN(parseFloat(target.dataset.cbiSpeed)) ? parseFloat(target.dataset.cbiSpeed) : 1;
			const pauseOnHover = target.dataset.cbiPauseOnHover === 'y';
			const autoScroll = speed === 0 ? false : {
				speed: speed,
				pauseOnHover: pauseOnHover,
				pauseOnFocus: pauseOnHover,
			};

			new Splide(target, {
				type: 'loop',
				drag: 'free',
				focus: 'center',
				// height: '24px',
				gap: 24,
				autoWidth: true,
				pagination: false,
				arrows: false,
				pauseOnHover: pauseOnHover,
				pauseOnFocus: pauseOnHover,
				autoScroll: autoScroll,
			}).mount(window.splide.Extensions);
		}
	};

	if (typeof Splide !== 'undefined') {
		ticker();
	} else {
		document.addEventListener("DOMContentLoaded", function () {
			ticker();
		});
	}
})();