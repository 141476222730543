/**
 * Packages and components run on the front-end of the site
 *
 * @package cbinsights
 */

import './legacy/navigation';
import './legacy/skip-link-focus-fix';
import './components/testimonial-tabs';
import './components/header-slider';
import './components/accordion';
import './components/filtered-content-grid';
import './components/ticker';
import './components/request-a-demo';
import './legacy/hubspot';
import BreadCrumbs from 'first-party-breadcrumbs';

window.BreadCrumbs = BreadCrumbs;

window.addEventListener('message', event => {
   if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
	   //console.log(BreadCrumbs.dump());
	   BreadCrumbs.formfill();
   }
   if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmit') {
   	   const emailField = event.data.data.find(field => field.name === 'email');
	   const emailValue = emailField ? emailField.value : null;
   	   BreadCrumbs.upsert('email', emailValue, true);
	   console.log(BreadCrumbs.dump());
   }
});