const hubSpot = {
	/**
	 *
	 */
	settings: {
		region: 'na1', // Currently they are all the same
		portalId: '763793', // Currently they are all the same
		formId: '', // "f01daeb8-accf-41ac-93be-d541776bf9d0"
	},

	form: function() {
		return hbspt.forms.create({
			region: this.settings.region,
			portalId: this.settings.portalId,
			formId: this.settings.formId,
			target: `div[data-hs_form_id='${this.settings.formId}']`
		});
	},

};

window.hubSpotFormLoader = (function() {
	function gatherAllForms() {
		return document.querySelectorAll('div[data-hs_form_id]');
	}

	function maybeLoadHsForm() {
		[...gatherAllForms()].forEach( (forms) => {
			initializeForm({
				formId: forms.getAttribute('data-hs_form_id'),
			});
		});
	}

	/**
	 *
	 * @param form Object containing; { region: '', portalId: '', formId: '' }
	 */
	function initializeForm({...form}) {
		hubSpot.settings.formId = form.formId;
		hubSpot.form();
	}

	return {
		init: function () {
			gatherAllForms();
			maybeLoadHsForm();
		}
	};
})();

window.hubSpotFormLoader.init();

document.addEventListener('breadcrumbs.before_init', function(e) {
	e.detail.options({
		domain: '.cbinsights.com',
		secure: true
	});
});
