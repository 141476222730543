let filter = document.getElementsByClassName('filter-select');
for (let i = 0; i < filter.length; i++) {
	filter[i].addEventListener('change', function(e) {
		let url = new URL(window.location);
		let params = url.searchParams;
		if (e.target.value){
			params.set(this.getAttribute('data-tax'), e.target.value);
		} else {
			params.delete(this.getAttribute('data-tax'));
		}
		url.search = params.toString();
		window.location = url.toString();
	});
}