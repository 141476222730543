(function () {

	/**
	 * Sets cookie value
	 *
	 * @param name
	 * @param value
	 * @param expiredays
	 */
	const cbiSetCookie = function (name, value, expiredays) {
		var exdate = new Date();
		exdate.setDate(exdate.getDate() + expiredays);
		document.cookie =
			name +
			"=" +
			escape(value) +
			(expiredays == null ? "" : "; expires=" + exdate.toGMTString()) +
			"; path=/";
	}

	const headerBannerEvents = function () {
		const $closeButtons = document.querySelectorAll('[data-js-header-banner-close]');
		[].forEach.call($closeButtons, function ($btn) {
			$btn.addEventListener('click', function () {
				const $banners = document.querySelectorAll('[data-js-header-banner]');
				let id = '';
				[].forEach.call($banners, function (banner) {
					banner.parentNode.removeChild(banner);
					id = banner.dataset.jsHeaderBanner;
				});
				cbiSetCookie("hide_header_banner" + id, 'T', 7);
				const event = new CustomEvent("header_banner_closed");
				document.dispatchEvent(event);
			});
		});
	};

	try {
		headerBannerEvents();
	} catch (e) {
		console.error(e);
	}

})();