/**
 * File navigation.js.
 *
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */
(function ($) {
	let container, button, menu, links, i, len;

	container = document.getElementById('site-navigation');
	if (!container) {
		return;
	}

	button = container.getElementsByTagName('button')[0];
	if ('undefined' === typeof button) {
		return;
	}

	menu = container.getElementsByTagName('ul')[0];

	// Hide menu toggle button if menu is empty and return early.
	if ('undefined' === typeof menu) {
		button.style.display = 'none';
		return;
	}



	menu.setAttribute('aria-expanded', 'false');
	if (-1 === menu.className.indexOf('nav-menu')) {
		menu.className += ' nav-menu';
	}

	button.onclick = function () {
		if (-1 !== container.className.indexOf('toggled')) {
			container.className = container.className.replace(' toggled', '');
			button.setAttribute('aria-expanded', 'false');
			menu.setAttribute('aria-expanded', 'false');
		} else {
			container.className += ' toggled';
			button.setAttribute('aria-expanded', 'true');
			menu.setAttribute('aria-expanded', 'true');
		}
	};

	// Get all the link elements within the menu.
	links = menu.querySelectorAll("a, .no-link");

	// Each time a menu link is focused or blurred, toggle focus.
	for (i = 0, len = links.length; i < len; i++) {
		links[i].addEventListener('focus', addFocus, true);
		links[i].addEventListener('blur', removeFocus, true);
	}

	/**
	 * Sets .focus class on an element.
	 */
	function addFocus() {
		// eslint-disable @typescript-eslint/no-this-alias
		var self = this;
		const is_mobile_menu_toggled = $('#site-navigation').hasClass('toggled');

		if (is_mobile_menu_toggled) {
			return;
		}

		// Move up through the ancestors of the current link until we hit .nav-menu.
		while (-1 === self.className.indexOf('nav-menu')) {

			// On li elements toggle the class .focus.
			if ('li' === self.tagName.toLowerCase()) {
				self.className += ' focus';
			}

			self = self.parentElement;
		}
	}

	/**
	 * Removes .focus class on an element.
	 */
	function removeFocus() {
		// eslint-disable @typescript-eslint/no-this-alias
		var self = this;
		const is_mobile_menu_toggled = $('#site-navigation').hasClass('toggled');

		if (is_mobile_menu_toggled) {
			return;
		}

		// Move up through the ancestors of the current link until we hit .nav-menu.
		while (-1 === self.className.indexOf('nav-menu')) {

			// On li elements toggle the class .focus.
			if ('li' === self.tagName.toLowerCase()) {
				self.className = self.className.replace(' focus', '');
			}

			self = self.parentElement;
		}
	}

	/**
	 * Applies the dropdown toggle.
	 */
	let submenu = $('#site-menu > li > .sub-menu');
	let sub_submenu = $('#site-menu .sub-menu .sub-menu');

	submenu.attr('role', 'menu').before('<span class="toggle-icon toggle-icon--sub" tabindex="0"><span class="screen-reader-text">Open Submenu</span></span>');
	sub_submenu.attr('role', 'menu').before('<span class="toggle-icon toggle-icon--sub-sub" tabindex="0"><span class="screen-reader-text">Open Submenu</span></span>');

	const site_menu_selector = '#site-menu';

	['click','ontouchstart'].forEach( evt =>
		$(site_menu_selector).on(evt, '.toggle-icon', toggleMenusOnButtonClick)
	);

	function toggleMenusOnButtonClick(e) {
		e.preventDefault();
		e.stopPropagation();

		// get immediate menu.
		let parent = $(e.target).parent('.menu-item-has-children');
		parent.toggleClass(['open', 'focus']);

		// get the nav menu
		let nav = $(e.target).closest('.nav-menu');

		// get the grandparent menu.
		let grandparent = $(e.target).closest('.nav-menu > .menu-item-has-children');

		nav.find('.menu-item-has-children').each(function(index){

			// remove classes from non related menus.
			if( $(this)[0] !== parent[0] && $(this)[0] !== grandparent[0]) {
				$(this).removeClass(['open', 'focus']);
			}
		});
	}

	$(site_menu_selector).on( 'keydown', '.focus', function( event ) {
		const allowedKeys = {
			up: 38,
			right: 39,
			down: 40,
			left: 37
		};

		if (Object.keys(allowedKeys).map((key => allowedKeys[key])).indexOf(event.which) === -1) {
			return;
		}

		const activeMenuClass = 'active';
		const dropDown = $('.sub-menu', this);

		const preventAndStopDefault = () => {
			event.preventDefault();
			event.stopPropagation();
		};

		const openMenu = () => {
			if (dropDown.length > 0) {
				preventAndStopDefault();
				$(this).addClass(activeMenuClass);
			}
		};

		const closeMenu = () => {
			if (dropDown.length > 0) {
				preventAndStopDefault();
				$(this).removeClass(activeMenuClass);
			}
		};

		switch (event.which) {
			case allowedKeys.right:
				openMenu();
				break;
			case allowedKeys.down:
				openMenu();
				break;
			case allowedKeys.left:
				closeMenu();
				break;
			case allowedKeys.up:
				closeMenu();
				break;
		}

	});

	$(site_menu_selector).on('keydown', 'site-navigation.toggled .toggle-icon', function(event) {
		if (event.which === 13) {
			$(this).parent('.menu-item').toggleClass('focus');
		}
	});

	$('.has-grandchildren:has(.feature-content)').addClass('has-feature-content');

	$(document).on( 'click', function(event) {

		let parent = $(event.target).parent();
		$('.sub-menu', parent).css('height', '');

		if(event.target.closest('.nav-menu > .menu-item-has-children')) {

			if(! $(event.target).attr('href') || $(event.target).attr('href') === '#') {
				event.preventDefault();
				event.stopPropagation();
			}

			let closest = $(event.target).closest('.nav-menu > .menu-item-has-children');
			let isOpen = closest.hasClass('open');

			if(!isOpen) {
				$('.menu-item-has-children.open').removeClass(['open', 'focus']);
				closest.addClass(['open', 'focus']);
			} else {
				if($(event.target).is('a') && (parent[0] === closest[0])) {
					parent.removeClass(['open', 'focus']);
				} else {
					// remove classes from siblings
					closest.find('.menu-item-has-children').each(function(index){
						if( $(this)[0] !== parent[0]) {
							$(this).removeClass(['open', 'focus']);
						}
					});
					parent.toggleClass(['open', 'focus']);
				}
			}
		} else {
			$('.menu-item-has-children').removeClass(['open', 'focus']);
		}

		let closestSub = $(event.target).closest('ul.sub-menu > li.menu-item-has-children');
		let subIsOpen = closestSub.hasClass('expanded');
		if(closestSub.length > 0) {
			if(!subIsOpen) {
				$(event.target).closest('.nav-menu ul.sub-menu').addClass('item-hovered');
				$('ul.sub-menu > li.menu-item-has-children.expanded').removeClass('expanded');
				closestSub.addClass('expanded');
				let total = 0;
				$('.menu-item', parent).each(function(index){
					let height = $(this).outerHeight(true);
					total += height;
				});
				let parents_submenus = parent.parent('.sub-menu');
				let parent_height    = parents_submenus.height();
				if (total > parent_height) {
					parents_submenus.height(total);
				} else {
					total = 0;
					parents_submenus.css('height', '');
				}
			} else {
				if($(event.target).is('a') && (parent[0] === closestSub[0])) {
					$('ul.sub-menu > li.menu-item-has-children.expanded').removeClass('expanded');
					$('ul.sub-menu.item-hovered').removeClass('item-hovered');
				}
			}
		} else {
			$('ul.sub-menu > li.menu-item-has-children.expanded').removeClass('expanded');
			$('ul.sub-menu.item-hovered').removeClass('item-hovered');
		}
	});

	onscroll = () => {
		let is_mobile_menu_toggled = $('#site-navigation').hasClass('toggled');
		if (is_mobile_menu_toggled === true) {
			return;
		}
		$('.menu-item').removeClass('open focus');
	}

	/**
	 * Close the menu and remove focus on the active element when a user
	 * alt-tabs between two applications so the menu does not stay open.
	 */
	onfocus = () => {
		$('.menu-item').removeClass('open focus');
		document.activeElement.blur();
	}

	/**
	 * Prevent scrolling from triggering onresize.
	 */
	const cachedWidth = $(window).width();
	onresize = () => {
		let newWidth = $(window).width();
		if(newWidth !== cachedWidth){
			if (container.classList.contains('toggled')) {
				container.classList.remove('toggled');
			}
		}
	}
})(window.jQuery);

const navbar = document.querySelector('#sitenav-container');
let top = navbar.offsetTop;
if (document.querySelector('.header-banner--desktop')) {
	top += document.querySelector('.header-banner--desktop').offsetHeight;
}

let disablePaddingCheck = false;
window.addEventListener('resize', () => {
	disablePaddingCheck = true;
});

window.addEventListener('scroll', () => {
	let marginTop = 0;
	// if (window.scrollY >= top) {
	// 	navbar.classList.add('sticky');
	// 	if (!disablePaddingCheck && window.matchMedia("(min-width: 920px)").matches) {
	// 		marginTop = navbar.offsetHeight;
	// 	}
	// } else {
	// 	navbar.classList.remove('sticky');
	// }
	try {
		document.getElementById('content').style.setProperty('--cbi-margin-top', `${marginTop}px`);
	} catch (e) {
		console.error(e);
	}
});
