import { debounce, fromEvent, timer } from "rxjs";

(function () {
	const tabInstances = document.getElementsByClassName('tabbed-content');

	if (0 < tabInstances.length) {
		for (let instance of tabInstances) {
			processTabHandlers(instance);
		}
	}

	/**
	 * Process an individual tab instance/element
	 *
	 * @param {HTMLElement} tabElement
	 */
	function processTabHandlers(tabElement) {
		const logos = Array.from(tabElement.getElementsByClassName('media__logo'));
		const testimonials = Array.from(tabElement.getElementsByClassName('tab'));
		const activeClass = 'current';

		// Skip processing if there are not both logos AND testimonials
		if (1 > logos.length || 1 > testimonials.length) {
			return;
		}

		logos.forEach((logo) => {
			fromEvent(logo, 'click')
				.pipe(debounce(() => timer(250)))
				.subscribe(event => tabHandler(event));
		});

		/**
		 * Click handler for a single logo
		 *
		 * @param {Event} clickEvent
		 */
		function tabHandler(clickEvent) {
			const tagName = clickEvent.target.tagName.toLowerCase();
			/**
			 * @type {HTMLElement}
			 */
			const logo = 'img' === tagName || 'p' === tagName ? clickEvent.target.parentElement : clickEvent.target;
			const testimonialId = logo?.dataset?.testimonial_id ?? 0;
			let targetTestimonial = null;

			if (0 > testimonialId) {
				return;
			}

			testimonials.forEach((testimonial) => {
				let currentId = testimonial?.dataset?.testimonial_id ?? 0;
				testimonial.classList.remove(activeClass);
				if (testimonialId === currentId) {
					targetTestimonial = testimonial;
				}
			});
			logos.forEach((logo) => {
				logo.classList.remove(activeClass);
			})

			if (targetTestimonial) {
				targetTestimonial.classList.add(activeClass);
			}
			logo.classList.add(activeClass);
		}
	}
})();
